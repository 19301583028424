import React from 'react';
import classes from './ScheduleRecord.module.css';
const ScheduleRecord = (props) => {
    

    const handleClick =  (event) => {        
        switch (event.detail) {
          case 1: {
            console.log('single click: ' + props.scheduleID);
            break;
          }
          case 2: {
            console.log('double clicked: sid: ' + props.scheduleID + ' start time: ' + props.starttime);
            // props.chooseSlot(props.scheduleID, props.starttime);
            break;
          }
          default: {
            break;
          }
        }
      };


    
    //console.log("timeslot: "  +props.scheduleID+ " " + props.ownername);
    // If tenant Name is 0 show tenant name if not - show start time, end time, room name. 
    return (<li className={classes.linePart} onClick={handleClick}>
               
                    <div className={classes.timePart}>     
                {props.endtime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })} - {props.starttime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })}</div>  <div className={classes.namePart}>  {props.roomName}</div>

                
    </li>);
}

export default ScheduleRecord;