export const backend = {
    httpmethod: process.env.REACT_APP_PROTOCOL + '://',
    backendURL: process.env.REACT_APP_BACKEND,
};
console.log("HTTP Method:", backend.httpmethod);
console.log("Backend URL:", backend.backendURL);

export const hours = {
    startHour: 6,
    endHour: 23,
    slotMinSize: 30    
};



