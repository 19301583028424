// import { Link } from 'react-router-dom';

import classes from './MainNavigation.module.css';
import HomeIcon from '@mui/icons-material/Home';
import { NavLink } from 'react-router-dom';
import AuthContext from '../../Store/auth-context';
import { useContext } from 'react';
import Button from '../UI/Button';


const MainNavigation = () => {
  const AuthCTX = useContext(AuthContext);
  const ExitHandler = () => {
    AuthCTX.logout();
  }

  return (
    <header className={classes.header}>
      {/* <Link to='/'> */}
      <NavLink to='/'>
        <HomeIcon color="primary"></HomeIcon>
        </NavLink>
      {!AuthCTX.isLoggedIn && (<div className={classes.logo}>אורח</div>)}
        
      {AuthCTX.isLoggedIn && (<div className={classes.logo}>{AuthCTX.userName}</div>)}
      <nav>
        <ul>
          <li>
            {/* <Link to='/auth'>Login</Link> */}
          </li>
          <li>
            {/* <Link to='/profile'>Profile</Link> */}
          </li>
          {AuthCTX.isLoggedIn && (
            <li>
            <Button onClick={ExitHandler}>יציאה</Button>
          </li>
          )}
          
        </ul>
      </nav>
    </header>
  );
};

export default MainNavigation;
