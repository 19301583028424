import Select from "react-select";
import classes from './MyTimePicker.module.css';
import { hours } from "../../settings";
import { useEffect, useState } from "react";


const MyTimePicker = (props) => {
  const [currentValue, setCurrentValue] = useState(new Date(props.timevalue));
  const lowLimit = props.lowLimit ? props.lowLimit : hours.startHour;
  const upLimit = props.upLimit ? props.upLimit : hours.endHour;
  const num_of_slots =
  (((upLimit - lowLimit) * 60) / hours.slotMinSize)+1;

  useEffect(() => {
     setCurrentValue(new Date(props.timevalue));
     console.log('current value set: ' + currentValue);
   },[props.timevalue]);

  const changeSelectedTime = (value) => {
    //console.log('new time: ' +  props.me + ' ' + value.value);
    props.onTimeChanged(value);
    setCurrentValue(value.value);
  }
  //console.log('current day: ' + props.CurrentDay)

  const slotStartHour = new Date(
    props.CurrentDay).setHours(lowLimit, 0, 0,0);
  let curScheduleID = 0;
  let isInSchedule = 0;
  let timeSlotArray = [];

    for (let i = 0; i < num_of_slots; i++) {
        let slotTimeStart = new Date(new Date(
            slotStartHour).getTime() + hours.slotMinSize * 60000 * i);
        timeSlotArray.push(slotTimeStart);
    }

    let timeArray = timeSlotArray.map(x => {return {value: x, label: x.getHours() + ':' + (x.getMinutes()<10 ? '0':'') + x.getMinutes()}});
    //console.log('selected date: ' + currentValue);
    //console.log('current value .gettime()' +  (currentValue ? currentValue.getTime():'none'));
    //console.log('props: ' + props.timevalue);
    const test = timeArray.filter((o)=>{return ( currentValue ?  currentValue.getTime() === o.value.getTime() : false)})
    return (
    <div tabIndex={-1}>
        <Select inputProps={{ readOnly: true }} isSearchable={ false } className={classes.selectBox} options={timeArray} onChange={changeSelectedTime} value={timeArray.filter((o)=>{return ( currentValue ?  currentValue.getTime() === o.value.getTime() : false)})}></Select>
    </div>
        );

}

export default MyTimePicker;
