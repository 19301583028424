import React, { useCallback, useState } from "react";



const NavContext = React.createContext({
    CurrentDay: {},
    DisplayMode: 1, // 1 - day  2 - week
    CurrentWeek: {},
    onChangeDay: (dayDate) => {},
    onChangeWeek: (firstDayOfWeek) => {},
    onChangeMode: (TimeMode) => {},
    onChangeCurrentRoom: (id) => {},
    onChangeCurrentTenant: (id) => {},
    onChagneTenantList: (tenantlist) => {},
    RoomList: {},
    TenantList: {},
    CurrentRoom: 1,
    CurrentTenant: 1,

});


export const NavContextProvider = (props) => {
    
    
    const [CurrentDay, setCurrentDay] = useState(new Date());
    const [CurrentWeek, setCurrentWeek] = useState();
    const [DisplayMode, setDisplayMode] = useState(1);
    const [RoomList, setRoomList] = useState(null);
    const [TenantList, setTenantList] = useState(null);
    const [CurrentRoom, setCurrentRoom] = useState(1);
    const [CurrentTenant, setCurrentTenant] = useState(1);

    const ChangeCurrentDayHandler = (cur) => {
        setCurrentDay(cur);

    }

    const ChangeCurrentWeekHandler = (cur) => {
        setCurrentWeek(cur);
     
    }

    const ChangeDisplayModeHandler = (mod) => {
        setDisplayMode(mod);
    }

    const ChangeCurrentRoomHandler = (id) => {
        setCurrentRoom(id);
    }

    const ChangeCurrentTenantHandler = (id) => {
        setCurrentTenant(id);
    }
    const changeRoomListHandler = useCallback((rooms) => {
        //console.log('change roome called: ' + rooms);
        setRoomList(rooms);
    },[]);

    const changeTenantListHandler = useCallback((tenants) => {
        console.log('change Tenants called ');
        setTenantList(tenants);
    },[]);
    
    


    return <NavContext.Provider value={
        {   CurrentDay: CurrentDay,
            CurrentWeek: CurrentWeek,
            DisplayMode: DisplayMode, 
            RoomList: RoomList,
            CurrentRoom: CurrentRoom,
            CurrentTenant: CurrentTenant,
            TenantList: TenantList,
            onChangeDay: ChangeCurrentDayHandler, 
            onChangeWeek: ChangeCurrentWeekHandler,
            onChangeMode: ChangeDisplayModeHandler,
            onChangeRoomList: changeRoomListHandler,
            onChagneTenantList: changeTenantListHandler,
            onChangeCurrentRoom: ChangeCurrentRoomHandler,
            onChangeCurrentTenant: ChangeCurrentTenantHandler
        }}>
        {props.children}
    </NavContext.Provider>
}

export default NavContext;