
import classes from './TimeSlot.module.css';
// import { useState, useCallback, useEffect } from 'react';
import React from 'react';
const Timeslot = (props) => {
    
    // const [myScheduleID, setMyScheduleID] = useState(null);
    // const [startTime, setStartTime] = useState(null);
    // useEffect(()=>{
    //   console.log('use effect called');
    //   setMyScheduleID(props.scheduleID);
    //   setStartTime(props.starttime);
    // },[])
    const handleClick =  (event) => {        
        switch (event.detail) {
          case 1: {
            console.log('single click: ' + props.scheduleID);
            break;
          }
          case 2: {
            console.log('double clicked: sid: ' + props.scheduleID + ' start time: ' + props.starttime);
            props.chooseSlot(props.scheduleID, props.starttime);
            break;
          }
          default: {
            break;
          }
        }
      };


    
    //console.log("timeslot: "  +props.scheduleID+ " " + props.ownername);
    return (<li onClick={handleClick}>
        <div className={`${classes.timeslot}  ${props.isHalf ? classes.timeslotHalf : ""}`} style={{backgroundColor: props.myColor ?  props.myColor : `rgba(245, 245, 130, 0.648)` }}>
        <span className={classes['timeslot-first-child']}>
            {props.starttime}
        </span>
        <p className={classes['timeslot-child']}>{props.ownername}</p>

        </div>
    </li>);
}

export default Timeslot;

