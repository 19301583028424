
import { Fragment, useContext, useEffect, useState} from 'react';
import './App.css';
import DayTimeSlots from './Components/Calendar/DayTimeSlots';
import Layout from './Components/Layout/Layout';
import NavContext from "./Store/nav-ctx";
import useHttp from "./hooks/use-http";
import {backend} from './settings';
import  Home from '@mui/icons-material/Home';
import  { Route, Routes } from 'react-router-dom';
import MainMenu from './Components/MainMenu';
import DayTenant from './Components/Calendar/DayTenant';
function App() {
  

  const navCTX = useContext(NavContext);
  const httpData  = useHttp();
  const {isLoading, error, sendRequest: fetchRooms } = httpData;
  const httpData2 = useHttp();
  const {isLoading2, error2, sendRequest: fetchTenants } = httpData2;

  const [subNavCode, setSubNavCode] = useState(0);
 useEffect(()=> {
  const requestConfig = {
    url: backend.httpmethod + backend.backendURL + '/api/tenants'
  };
  console.log('calling fetch tenants');
  fetchTenants(requestConfig,navCTX.onChagneTenantList); 
}, [fetchTenants, navCTX.onChagneTenantList]);
  
  useEffect(()=> {
    const requestConfig = {
      url: backend.httpmethod + backend.backendURL + '/api/rooms'
    };
    console.log('calling fetch rooms');
    fetchRooms(requestConfig,navCTX.onChangeRoomList); 
  }, [fetchRooms, navCTX.onChangeRoomList]);

  
  const UpdateSubNavigation = (code) => {
    console.log('set sub nav called with: ' +code);
    setSubNavCode(code);

  }



  return (  
    <Fragment>
      <Layout NavCode={subNavCode}>
        <Routes>
          <Route path="/daybyroom" element={<DayTimeSlots setSubNav={UpdateSubNavigation}></DayTimeSlots>} />
          <Route path="/daybytenant" element={<DayTenant setSubNav={UpdateSubNavigation}></DayTenant>} />
          <Route path="/*" element={<MainMenu setSubNav={UpdateSubNavigation} />} />
        </Routes>
        </Layout>
    </Fragment>
  );
}

export default App;
