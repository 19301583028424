import React, { useState, useEffect, useReducer , useContext, useRef} from 'react';

import Card from '../UI/Card';
import classes from './Login.module.css';
import Button from '../UI/Button';
import Input from '../UI/Input'
import useHttp from '../../hooks/use-http';
import { backend } from "../../settings";
import AuthContext from '../../Store/auth-context';


// import AuthContext from '../../store/auth-context';
const userReducer = (state, action) => {
  if(action.type === 'USER_INPUT')
  {
    console.log('user inside: ' + action.val.length === 7 );
    return ({value: action.val, isValid: action.val.length === 7});
  }
  if(action.type === 'INPUT_BLUR') // loose focus.
  {
    console.log('user inside: ' + state.value.length === 7);
    return ({value: state.value, isValid: state.value.length === 7});
  }
  console.log('user inside3: ');
  return {value: '', isValid: false};
};

const passwordReducer = (state, action) => {
  console.log('inside pwd reducer: '  + action.type);
  if(action.type === 'USER_INPUT')
  { 
    console.log('pass inside1: ' + action.val.trim().length > 6);  
    return ({value: action.val, isValid: action.val.trim().length > 6});
  }
  if(action.type === 'INPUT_BLUR')
  {
    console.log('pass inside2: ' + state.value.trim().length > 6);  
    return ({value: state.value, isValid: state.value.trim().length > 6});
  }
  console.log('passinside3');

  return {value: '', isValid: false};
};


const Login = (props) => {

  const [formIsValid, setFormIsValid] = useState(false);
  
  const [userState, dispatchUser] = useReducer(userReducer,{value: '', isValid: null});
  const [passwordState, dispatchPassword] = useReducer(passwordReducer,{value: '', isValid: null});

    const userInputRef = useRef();
    const passwordInputRef = useRef();


  // valid the form based on validity change only, and after 500 ms
  const { isValid: userValid} = userState;
  const {isValid: passwordValid} = passwordState;
  let hashedPassword
  const authCtx =  useContext(AuthContext);
  useEffect(() => {
    const timer = setTimeout(() => {
      setFormIsValid(
        userValid && passwordValid
      )
    //   if(userValid && passwordValid) {
    //     bcrypt.genSalt(12, (error, salt) => {
    //       bcrypt.hash(passwordState.value, salt, (err,hash) => {
    //         if(err)
    //         {
    //           console.error('unable to hash password');
    //         }
    //         console.log(hash);
    //         hashedPassword = hash;
    //       })
    //   });
    // }
  },500);

    // This is a clean up function - it will run before every run (not the first one)
    return () => {
      clearTimeout(timer);
    };
  },[userValid, passwordValid])

  const userChangeHandler = (event) => {
    dispatchUser({type: 'USER_INPUT', val: event.target.value});
    console.log('User: ' + userState.isValid);
    console.log('Pass:' + passwordState.isValid);    
  };

  const passwordChangeHandler = (event) => {
    console.log("dispatching password");
    dispatchPassword({type: 'USER_INPUT', val: event.target.value});
  };

  const validateUserHandler = () => {
    //setuserIsValid(userState.isValid);
    dispatchUser({type: 'INPUT_BLUR'});
  };

  const validatePasswordHandler = () => {
    // setPasswordIsValid(enteredPassword.trim().length > 6);
    dispatchPassword({type: 'INPUT_BLUR'});
  };


  const HandleLogin = (loginData) => {
    if(loginData)
    {
      authCtx.login(loginData.token);
      
    } else
    {
      console.log("empty resutls");
    }

    //authCtx.onLogin(userState.value, passwordState.value);
  }
  


  const httpData = useHttp();
      const { isLoading, httpError: isError, sendRequest: doLogin } = httpData;
      const requestConfig = {
        url: 
          backend.httpmethod +
          backend.backendURL +
          "/api/auth",
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },headers: {
      'Content-Type': 'application/json'
        },
        body: {
          Password: passwordState.value,
          PersonalID: userState.value
        }
      } ;

  const submitHandler = (event) => {
    event.preventDefault();
    if(formIsValid)
    { 
      console.log('hash: '+  hashedPassword);    
      doLogin(requestConfig, HandleLogin);      
    } else if (!userValid) {
        userInputRef.current.focus();
    }  
        else {
        passwordInputRef.current.focus();
        }
    }
  

  return (
    <Card className={classes.login}>
      <form onSubmit={submitHandler}>
        <Input ref={userInputRef} id="user" label="מ.אישי" type="text" isValid={userState.isValid} value={userState.value} onChange={userChangeHandler} onBlur={validateUserHandler}/>
        

        <Input ref={passwordInputRef} id="password" label="סיסמא" type="password" isValid={passwordState.isValid} value={passwordState.value} onChange={passwordChangeHandler} onBlur={validatePasswordHandler}/>
          
        
        <div className={classes.actions}>
          <Button type="submit" className={classes.btn} disabled={!formIsValid}>
            Login
          </Button>
        </div>
      </form>
      {isError && <p>תקלה בכניסה למערכת</p>}
    </Card>
  );
};

export default Login;
