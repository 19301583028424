import useHttp from "../../hooks/use-http";
import classes from "./DayTimeSlots.module.css";
import Timeslot from "./TimeSlot";
import { useCallback, useContext, useEffect, useState } from "react";
import { backend } from "../../settings";
import { hours } from "../../settings";
import NavContext from "../../Store/nav-ctx";
import AuthContext from "../../Store/auth-context";
import React from "react";
import AddSchedule from './AddSchedule';

// this component will draw the timeslot for one day.
// it needs to get the schedules
// it needs to build the slots
// in needs to listen to change room events, and change current day event

const DayTimeSlots = (props) => {
  useEffect(() => {
    console.log('setting sub nav to 1');
    props.setSubNav(1);
  },[]);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const navCTX = useContext(NavContext);
  const authCTX = useContext(AuthContext);
  const [timeSlotsArray, setTimeSlotsArray] = useState(null);
  const [addScheduleIsShown,setAddScheduleIsShown] = useState(false);
 
  // Parameters to support add schedule dialog: 
  const [selectedScheduleID, setSelectedScheduleID] = useState(0);
  const [selectedTimeSlotStart, setSelectedTimeSlotStart] = useState(null);
  const [passToAdd, setpassToAdd] = useState(null);
  const [doReload, setDoReload] = useState(false);
  
  const [scheduleList, setScheduleList] = useState(null);

  const HandleSchedules = (schedules) => {
    console.log("schedules data recived: " + schedules ? schedules.length : 0);
    setDoReload(false);
    // convert schdule object to real dates:
    const objSchedules = schedules.map((x) => {
      return { ...x, From: new Date(x.From), To: new Date(x.To) };
    });
    setScheduleList(objSchedules);
    console.log("current schedules after reload: " + objSchedules.length);
    // from start hour to end hour with steps create a slot
    // check if the slot included in a schedule
    // if it's first - add all data
    // if it's second - mark and add color
    // if it's last - mark
    // we assume no overlapping schedules.

    const num_of_slots =
      ((hours.endHour - hours.startHour) * 60) / hours.slotMinSize;
    const slotStartHour = new Date(
      navCTX.CurrentDay.setHours(hours.startHour, 0, 0,0)
    );
    let curScheduleID = 0;
    let isInSchedule = 0;
    let timeSlotArray = [];
    
    let LastSchedule = 0;
    for (let i = 0; i < num_of_slots; i++) {
      console.log('Calculating slots');
      
      let slotTimeStart = new Date(
            slotStartHour.getTime() + hours.slotMinSize * 60000 * i
          );
      let slotTimeEnd = new Date(
            slotTimeStart.getTime() + hours.slotMinSize * 60000
        );
      let timeSlot = {
        key: i,
        startTime: slotTimeStart.getHours() + ':' + slotTimeStart.getMinutes().toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false,
            
          }),
          scheduleID: 0
      }
      
      if (schedules !== null && schedules.length > 0) {
        let curSchedule = objSchedules.filter(
          (x) =>
            (slotTimeStart >= x.From && slotTimeStart < x.To ) || (slotTimeEnd > x.From && slotTimeEnd <= x.To)
        );
        if (curSchedule.length === 1) {
          // todo: assert you have only 1

          if (isInSchedule && curSchedule[0].ScheduleID === LastSchedule) {
            timeSlot = {
              ...timeSlot,
              color: curSchedule[0].TenantColor,
              scheduleID: curSchedule[0].ScheduleID,
            };
            timeSlotArray.push(timeSlot);
            LastSchedule = curSchedule[0].ScheduleID;
          } else {
            isInSchedule = true;
            timeSlot = {
             ...timeSlot,
              color: curSchedule[0].TenantColor,
              ownerName: curSchedule[0].TenantName,
              scheduleID: curSchedule[0].ScheduleID,
              isFirst: true,
            };
            LastSchedule = curSchedule[0].ScheduleID;
            timeSlotArray.push(timeSlot);
          }
        } else if (curSchedule.legth > 1) {
          console.log("OVERLAPPING !!!");
          throw isError("Overlapping schedules on the same slot");
        } else {
          isInSchedule = false;
          timeSlotArray.push(timeSlot);
        }
      } else {
        isInSchedule = false;
        timeSlotArray.push(timeSlot);
      }
    }
    setTimeSlotsArray(timeSlotArray);
  };

  ///  Fetch Sechdules by room and date
  const httpData = useHttp();
  const { isLoading, httpError: isError, sendRequest: fetchSchedules } = httpData;
  const roomID = navCTX.CurrentRoom !== null ? navCTX.CurrentRoom : 1;
  const Today = navCTX.CurrentDay;
  console.log(typeof Today);
  console.log(hours.startHour);
  const from = new Date(
    Today.setHours(hours.startHour-2, 0, 0, 0) -
      Today.getTimezoneOffset() * 60 * 1000
  )
    .toISOString()
    .slice(0, 19);
  const to = new Date(
    Today.setHours(hours.endHour, 59, 59, 999) -
      Today.getTimezoneOffset() * 60 * 1000
  )
    .toISOString()
    .slice(0, 19);

  useEffect(() => {
    console.log('loading data....' + doReload);
    const requestConfig = {
      url:
        backend.httpmethod +
        backend.backendURL +
        "/api/schedule/" +
        roomID +
        "/" +
        from +
        "/" +
        to,
    };
    console.log("calling fetch schedule: " + requestConfig.url);
    fetchSchedules(requestConfig, HandleSchedules);
  }, [from, to, roomID, doReload]);
  //fetchSchedules(requestConfig,HandleSchedules);

  /// Build Slots

  ///
  const reload = () => {
    setDoReload(true);
    console.log("current schedules before reload: " + scheduleList.length);
    setScheduleList(null);
    console.log('reload');
  }

  const showAddScheduleHandler = () => {
    // if scheduleID is zero - use start time to set the Dialog
    // also pass the selected room     
    // if shcheduleID is > 0 pass it and the dialog will auto populate it self from DB. 
    console.log('SelectedRoom: ' + navCTX.CurrentRoom)
    setAddScheduleIsShown(true);
  }

  const hideAddScheduleHandler = () => {
    setAddScheduleIsShown(false);
  }

  const rowClicked = (scheduleID, startTime) => {
    console.log('row clicked: ScheduleID: ' + scheduleID + ' startTime: ' + startTime);
    if(scheduleID && scheduleID !== 0)
    {
      let mySelectedSchedule = scheduleList.filter((x) =>
      (x.ScheduleID === scheduleID));
      if(mySelectedSchedule)
      {
        setpassToAdd(mySelectedSchedule[0]);
      }

    } else
    {
      
      setSelectedTimeSlotStart(startTime);
    }
    setSelectedScheduleID(scheduleID);
    showAddScheduleHandler();
  }


  return (
    <React.Fragment>
      { authCTX.isLoggedIn &&  addScheduleIsShown && <AddSchedule currentDate onClose={hideAddScheduleHandler} scheduleID={selectedScheduleID} ClickedStartTime={selectedTimeSlotStart} selectedRoomID={navCTX.CurrentRoom} selectedSchedule={passToAdd} reload={reload}></AddSchedule>}
      {isLoading && (<p style={{textAlign: "center"}}>Loading....</p>)}
      
      {isError && (<p style={{textAlign: "center"}}>Backend server error</p>)}
      {!isError && (
      <ul className={classes.daytimeslots}>
        {timeSlotsArray !== null && timeSlotsArray.map(x => (
            
                <Timeslot isHalf={x.startTime.endsWith("00") ? true : false} key={x.key} ownername={x.ownerName} starttime={x.startTime} myColor={x.color} scheduleID={x.scheduleID} chooseSlot={rowClicked}></Timeslot>
            ))}
    </ul>)}
    {doReload && (<p style={{textAlign: "center"}}>Loading....</p>)}
    </React.Fragment>
  );
};

export default DayTimeSlots;
