

import React, {useState, useEffect, useCallback} from "react";
import jwt_decode from 'jwt-decode';

let logoutTimer;
const AuthContext = React.createContext({
    token: '',
    isLoggedIn: false,
    userName: '',
    userID: 0,
    login: (token) => {},
    logout: () => {}
});

const calcRemainingTime = (expirationTime) =>  {
    const currentTime = new Date().getTime();
    console.log('current time: ' + currentTime); 
    const adjExpirationTime = new Date(expirationTime).getTime();
    console.log('adjExpirationTime time: ' + adjExpirationTime); 
    return adjExpirationTime - currentTime;
}

const retrieveStoredToken = () => {
    const storedToken = localStorage.getItem('token');
    const storedExpirationDate = localStorage.getItem('expirationTime');

    const remainingTime = calcRemainingTime(storedExpirationDate);
    if(remainingTime <= 60000) {
        localStorage.removeItem('token');
        localStorage.removeItem('expirationTime');
        console.log('Revoking token !!');
        return null;
    }
    return {
        token: storedToken,
        duration: remainingTime
    }

}

const decodeToken = (token) => {
    console.log(JSON.stringify(jwt_decode(token)));
    return(jwt_decode(token));
} 

export const AuthContextProvider = (props) => {

    console.log('getting token data from local store');
    const tokenData = retrieveStoredToken();
    let initialToken;
    let tokenDataDecoded;
    if(tokenData) {
        console.log('token exists in local store');
        initialToken = tokenData.token;
        tokenDataDecoded = decodeToken(initialToken);
    } else
    {
        console.log('tokenData is null/false');
    }
     

    const [token, setToken] = useState(initialToken);
    if(tokenDataDecoded)
    {
        console.log('decoded from local store: ' + tokenDataDecoded.UserName);
    } else
    {
        console.log('no token data');
    }
    const [userName, setUserName] = useState(tokenDataDecoded ? tokenDataDecoded.UserName : '');
    const [userID, setUserID] = useState(tokenDataDecoded ?tokenDataDecoded.UserID : 0);
    console.log('UserName: ' + userName);
    const userIsLoggedIn = !!token;
    
    const logoutHandler = useCallback( () => {
        setToken(null);
        localStorage.removeItem('token');
        localStorage.removeItem('expirationTime');
        if(logoutTimer) {
            clearTimeout(logoutTimer);
        }
        
    },[]);

   
    const loginHandler = (token) => {
          console.log('entering login handler');
          setToken(token);
          const {UserID, UserName,exp} = jwt_decode(token);
          setUserID(UserID);
          setUserName(UserName);
          const expirationTime = new Date(exp*1000);
          console.log('expiration time: ' + expirationTime);
          localStorage.setItem('token', token);
          localStorage.setItem('expirationTime', expirationTime); 
          const remainingTime = calcRemainingTime(expirationTime);
          console.log('remaining time: ' + remainingTime);
          logoutTimer = setTimeout(logoutHandler, remainingTime);
    };

   useEffect(() => {
    if(tokenData) {
        console.log('token data is not null but changed setting timeout to: ' + tokenData.duration);
        logoutTimer = setTimeout(logoutHandler, tokenData.duration);
    }
   },[tokenData, logoutHandler]);

    const contextValue = {
        token:token,
        isLoggedIn: userIsLoggedIn,
        userID: userID, 
        userName: userName,
        login: loginHandler,
        logout: logoutHandler,
    }
    return <AuthContext.Provider value={contextValue}>{props.children}</AuthContext.Provider>;
};

export default AuthContext;
