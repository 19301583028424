import { useContext } from "react";
import NavContext from "../../Store/nav-ctx";
import classes from "./TenantList.module.css";

const TenantList = () => {
    const navCTX = useContext(NavContext);

    const myTenantList = navCTX.TenantList;
    console.log(myTenantList ? "tenant: " + myTenantList.length : 'No Tenants');
    return(
        <ul className={classes.tenantlist}>
        {myTenantList && myTenantList.map( (tenant)=>(<li key={tenant.TenantID} style={{display: "flex"}}><div style={{width: "7rem"}}> {tenant.TenantName}</div><div style={{width:"3rem",marginRight: "2rem", height:"1rem",border:"1px",solid: "#FFFFFF" ,backgroundColor: tenant.TenantColor}}></div></li>))}
        </ul>
    );
}

export default TenantList;