import { NavLink } from 'react-router-dom';
import Login from './Auth/Login'
import { Fragment, useEffect } from 'react';
import Button from './UI/Button';
import classes from './MainMenu.module.css';
import TenantList from './Tenants/TenantList';
const MainMenu = (props) => {
    useEffect(() => {
        props.setSubNav(0);
      },[]);


    return <Fragment>
        <div>
        <NavLink to='/daybyroom'><Button className={classes.menubtn}>לוח יומי לפי משאב</Button></NavLink>
        <NavLink to='/daybytenant'><Button className={classes.menubtn}>לוח יומי לפי קורס</Button></NavLink>
        </div>
        <div>
        
        </div>
    <Login></Login>
    <TenantList></TenantList>
    </Fragment>
};


export default MainMenu;