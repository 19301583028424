import classes from './DisplayDate.module.css';

const DisplayDate = (props) => {
    var dateObj = props.currentDate;
    var month = dateObj.getUTCMonth() + 1; //months from 1-12
    var day = dateObj.getUTCDate();
    var year = dateObj.getUTCFullYear();


    return (<div className={classes.displaydate}>
        {day + '/' + month + '/' + year}
        </div>);

}

export default DisplayDate;