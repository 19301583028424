import Modal from '../UI/Modal';
import Input from '../UI/Input';
import { useContext, useRef, useState, useReducer, useEffect } from 'react';
import NavContext from '../../Store/nav-ctx';
import AuthContext from '../../Store/auth-context';
import Select from "react-select";
import classes from "./AddSchedule.module.css";
import { CirclePicker, HuePicker } from 'react-color';
import DisplayDate from './DisplayDate';
import MyTimePicker from '../UI/MyTimePicker';
import Button from '../UI/Button';
import useHttp from "../../hooks/use-http";
import {backend} from '../../settings';
import {hours} from '../../settings';
import { CacheProvider } from '@emotion/react';



const AddSchedule = (props) => {
    const navCTX = useContext(NavContext);
    const authCtx =  useContext(AuthContext);
    const rooms = navCTX.RoomList;
    const tenants = navCTX.TenantList;
    const roomsOptions = rooms ? rooms.map( (room)=> ({value: room.RoomID, label: room.RoomName})) : console.error('no room list');
    const tenantOptions = tenants ? tenants.map( (tenant)=> ({value: tenant.TenantID, label: tenant.TenantName, TenantColor: tenant.TenantColor})) : console.error('no tenant list');



    const createTimeFromHourMin = (hourmin,doadd) => {
        const currentDate = navCTX.CurrentDay;
        var hrs = hourmin ? hourmin.split(':')[0] : '';
        var mins = hourmin ? hourmin.split(':')[1]: '';
        if(hrs=='')
        {
            return null
        }
        let slotStartHour = new Date(new Date(
            currentDate).setHours(hrs,mins, 0,0));
            if(doadd)
            {
                slotStartHour.setMinutes(slotStartHour.getMinutes() + 30);
            }
        console.log('slotstarthour: ' + hours.slotMinSize);
        return slotStartHour;
    }


    const [openTenantColor, setOpenTenantColor] = useState('#FFD700');
    const [scheduleID, setScheduleID] = useState(0);
    const [selectedTenant, setSelectedTenant] = useState(null);
    const [selectedTenantID, setSelectedTenantID] = useState(null);
    const [selectedSchedule, setSelectedSchedule] = useState(null);
    const [roomID, setRoomID] = useState(navCTX.CurrentRoom);

    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);

    const setTenantFromID = (ID) => {
        const tmpTenantArray = (tenants.filter((tenant) => {
            return +tenant.TenantID === +ID;
        }));
        if (tmpTenantArray && tmpTenantArray.length > 0) {
            setSelectedTenant(tmpTenantArray[0]);
            setSelectedTenantID(tmpTenantArray[0].TenantID);
            console.log('setting selected tenant: ' + tmpTenantArray[0].TenantName);
        }
    }


    useEffect(()=>{
        if(props.scheduleID && props.scheduleID > 0)
        {
            setScheduleID(props.scheduleID);
            setSelectedSchedule(props.selectedSchedule);
            setTenantFromID(props.selectedSchedule.TenantID);
            setStartTime(props.selectedSchedule.From);
            setEndTime(props.selectedSchedule.To);
            if(props.selectedSchedule.TenantID == 0)
            {
                setOpenTenant(props.selectedSchedule.TenantName);
                dispatchOpenTenant({type: 'USER_INPUT', val: props.selectedSchedule.TenantName});
                setOpenTenantColor(props.selectedSchedule.TenantColor)
            }
        } else
        {
            console.log('setting start and end time');
            setStartTime(createTimeFromHourMin(props.ClickedStartTime, false));
            setEndTime(createTimeFromHourMin(props.ClickedStartTime, true));
        }
    },[props.scheduleID]);
    
    /// open Tenant Input block:

    const [openTenant, setOpenTenant] = useState(null);
    
    /// Validates OpenTenant is more than 2 letters. if focus out - it will not be valid until 3 letters typed. 
    const openTenantReducer = (state, action) => {
        if(action.type === 'USER_INPUT')
        {
          console.log('New Tenant: ' + action.val.length > 2 );
          setOpenTenant(action.val);
          return ({value: action.val, isValid: action.val.length > 2});
        }
        if(action.type === 'INPUT_BLUR') // loose focus.
        {
          console.log('New Tenant: ' + state.value.length > 2);
          return ({value: state.value, isValid: state.value.length > 2});
        }
        console.log('user inside3: ');
        return {value: '', isValid: false};
      };

      const [openTenantState, dispatchOpenTenant] = useReducer(openTenantReducer,{value: '', isValid: null});
      
      const openTenantChangeHandler = (event) => {
        dispatchOpenTenant({type: 'USER_INPUT', val: event.target.value});
        console.log('open Tenant: ' + openTenantState.isValid);    
      };

      const validateOpenTenantHandler = () => {       
        dispatchOpenTenant({type: 'INPUT_BLUR'});
      };

      const openTenantInputRef = useRef();
       
     const otherTenantInputRef = useRef();
    const otherColorInputRef = useRef();
    const changeSelectedRoom = () => {};
    
    
    const changeTenant = (mySelectetdTenant) => {
        console.log(tenants.length);
        if(mySelectetdTenant != null) {
            console.log('selectedTenant before is: ' + mySelectetdTenant.value);
        } else
        {
            console.log('mySelectetdTenant is null');
        }
        if(selectedTenant !== null) {
            console.log('state selected tenant: ' + selectedTenant.TenantID);
        } else
        {
            console.log('selectedTenant is null');
        }
        const tmpTenantArray = (tenants.filter((tenant) => {
            return +tenant.TenantID === +mySelectetdTenant.value;
        }));
        if (tmpTenantArray && tmpTenantArray.length > 0) {
            setSelectedTenant(tmpTenantArray[0]);
            setSelectedTenantID(tmpTenantArray[0].TenantID);
            console.log('setting selected tenant: ' + tmpTenantArray[0].TenantName);
        }
        console.log('state selected tenant: ' + (selectedTenant ? selectedTenant.TenantID : 'none'));

        

    };

    const onColorChange = (color) => {
        console.log('color change:' + color);
        setOpenTenantColor(color.hex);
    }

    const onStartTimeChange = (newTime) => {
        // set new start time
        console.log('add scehdule new time: ' + newTime.value ? newTime.value : 'undefined');
        setStartTime(newTime.value);
        // check if end time is lower than start time - if it is lower - move it to be 1 hour post start time. 
        if(newTime.value >= endTime || !endTime)
        {
            let newned = new Date(newTime.value.getTime());
            onEndTimeChange(newned.setHours(newned.getHours() + 1));
            console.log('new time after end time set: ' + newTime.value);
        }
    }

    const onEndTimeChange = (newTime) => {
        console.log("end time: " + newTime.value ? newTime.value : 'undefined');
        setEndTime(newTime.value);
    }


    const GetTenantDataToSave = () => {
       let SchedToSave = {}
        if(selectedTenantID===0)
    {
        SchedToSave = {
            ScheduleID: scheduleID,
            TenantName: openTenantState ? openTenantState.value : '',
            TenantColor: openTenantColor,
            From: startTime,
            To: endTime,
            RoomID: roomID,
            TenantID: selectedTenantID,
            Comment: 'Not implemented yet',
            UserID: authCtx.userID 
        }
    } else {
        SchedToSave = {  
            ScheduleID: scheduleID,
            TenantName: selectedTenant ? selectedTenant.TenantName: '',
            TenantColor: selectedTenant? selectedTenant.TenantColor: '',
            From: startTime,
            To: endTime,
            RoomID: roomID,
            TenantID: selectedTenantID,
            Comment: 'Not implemented yet',
            UserID: authCtx.userID
        }
    }
    return SchedToSave;
    }
    

    /// DELETE HANDLING CODE: 

    const httpDataDel = useHttp();
    const { isLoading, httpError: isError, sendRequest: doDelete } = httpDataDel;
    const delRequestConfig = {
        url: 
          backend.httpmethod +
          backend.backendURL +
          "/api/schedule/" + scheduleID,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authCtx.token}`
        },
      } ;

  
    const HandleDelResults = (results) => {
        console.log("data recived: " + results);
        props.reload();
    }
    const delSchedule = (scheduleID) => {
        doDelete(delRequestConfig,HandleDelResults);

    }

    const delScheduleHandler = () => {       
        if (!scheduleID || scheduleID == 0)
        {
            if(window.confirm("לסגור ללא שמירה ? "))
            {
                
                console.log('closing the dialog, no changes made');
                props.onClose();
                return;
            }

            return;
           
        } else
        {
            if(window.confirm("למחוק ? "))
            {
            console.log('deleting the schdeule from server, than closing');
            delSchedule(scheduleID);
            props.onClose();
            } else
            return;
            // call delete schedule with Schedule ID
        }
    }

    // Add Schedule Code:

    // ScheduleID
    // TenantName
    // TenantColor
    // From
    // To
    // RoomID
    // TenantID
    // Comment
    // UserID

    const httpDataADD = useHttp();
    const { isAddLoading, httpError: isAddError, sendRequest: doAdd } = httpDataADD;
   
    


    const addRequestConfig = {
        url: 
          backend.httpmethod +
          backend.backendURL +
          "/api/schedule/",
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authCtx.token}`
        },
        body: GetTenantDataToSave()
      } ;

  
    const HandleAddResults = (results) => {
        console.log("data recived: " + results);
        props.reload({});
        props.onClose(); 
        return;
    }
    const AddSchedule = (scheduleID) => {
        doAdd(addRequestConfig,HandleAddResults);

    }


    // Update Schedule Code:
    // Add Schedule Code:

    // ScheduleID
    // TenantName
    // TenantColor
    // From
    // To
    // RoomID
    // TenantID
    // Comment
    // UserID

    const httpDataUpdate = useHttp();
    const { isUpdateLoading, httpError: isUpdateError, sendRequest: doUpdate } = httpDataUpdate;
    const updateRequestConfig = {
        url: 
          backend.httpmethod +
          backend.backendURL +
          "/api/schedule/" + scheduleID,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authCtx.token}`
        },
        body: GetTenantDataToSave()
            // {  
            //     ScheduleID: scheduleID,
            //     TenantName: selectedTenant ? selectedTenant.TenantName: '',
            //     TenantColor: selectedTenant? selectedTenant.TenantColor: '',
            //     From: startTime,
            //     To: endTime,
            //     RoomID: roomID,
            //     TenantID: selectedTenantID,
            //     Comment: 'Not implemented yet',
            //     UserID: authCtx.userID


            // }
      } ;

  
    const HandleUpdateResults = () => {
        console.log("updated" );
        props.reload({});
        props.onClose(); 
        return;
    }
    const UpdateSchedule = (scheduleID) => {
        doUpdate(updateRequestConfig,HandleUpdateResults);

    }
    /// End upadte Schedule Code




    const addUpdateScheduleHandler = async () => {    
        if(startTime.getTime() === endTime.getTime())
        {
            // todo - set error 
            return;
        }   
        if (!scheduleID || scheduleID == 0)
        {
          
           // try {

            
            doAdd(addRequestConfig,HandleAddResults);
            // if(!isAddError)
            // {
            //    // props.reload({});  
            //    // props.onClose();
            // }
            // } catch (error)
            // {
            //     console.error(error);
            // }
           // return;
           
        } else
        {           
            doUpdate(updateRequestConfig,HandleUpdateResults);
            // //.then(() => {
            //     if(!isUpdateError)
            //     {
            //         props.reload({});  
            //         props.onClose();           
            //     }
            //    return;
            //});
            
            
        }
    }

    // Update Schedule Code:

    const closeScheduleHandler = () => {
        if(window.confirm("סגור חלון ללא שמירה? "))
            {
                
                console.log('closing the dialog, no changes made');
                props.onClose();
                return;
            } else
            return;
    }
    console.log("selected Tenant ID:" + selectedTenantID);  


    return (
        <Modal>
            <DisplayDate currentDate={navCTX.CurrentDay}></DisplayDate>
            {isUpdateError && (<p style={{textAlign: "center", color:"red"}}>{isUpdateError}</p>)}
            {isAddError && (<p style={{textAlign: "center", color:"red"}}>{isAddError}</p>)}
            {isError && (<p style={{textAlign: "center", color:"red"}}>{isError}</p>)}
            <div tabIndex={-1}>
            
            <Select inputProps={{ readOnly: true }} isSearchable={ false } className={classes.selectBox} options={roomsOptions} onChange={changeSelectedRoom} value={roomsOptions.filter(function(option) {return option.value === navCTX.CurrentRoom;})}></Select>

            </div>
            <div tabIndex={-1}>

            <Select inputProps={{ readOnly: true }} isSearchable={ false } className={classes.selectBox} options={tenantOptions} onChange={changeTenant} value={tenantOptions.filter(function(option) {return option.value === selectedTenantID;})}></Select>

            </div>
            {selectedTenant && selectedTenant.TenantID === 0 && <Input ref={openTenantInputRef} id="openTenant" label="שיבוץ פתוח:" type="text" isValid={openTenantState.isValid} value={openTenant} onChange={openTenantChangeHandler} onBlur={validateOpenTenantHandler}/>}
            {selectedTenant && selectedTenant.TenantID === 0 && <CirclePicker colors={['#FAFAD2', '#B22222', '#191970', '#98FB98', '#FF1493', '#FFD700', '#00FF00', '#00FFFF', '#DAA520', '#FFFF00']} color={openTenantColor} onChangeComplete={onColorChange} onChange={onColorChange}/>  }
            {selectedTenant && selectedTenant.TenantID !==0  && <div style={{marginTop: "10px", marginRight: "2rem", height:"1rem",border:"1px",solid: "#FFFFFF" ,backgroundColor: selectedTenant.TenantColor}}></div>}
            {selectedTenant && selectedTenant.TenantID === 0 && <div id='openTenantColorShow' style={{marginTop: "10px", marginBottom: "5px", height:"1rem",border:"1px", solid: "#FFFFFF", backgroundColor: openTenantColor}}></div>}
            <div tabIndex={-1}><p>התחלה:</p><MyTimePicker me='start' CurrentDay={navCTX.CurrentDay} onTimeChanged={onStartTimeChange} timevalue={startTime}></MyTimePicker></div>
            <div tabIndex={-1}><p>סיום:</p><MyTimePicker me='end' CurrentDay={navCTX.CurrentDay} onTimeChanged={onEndTimeChange} timevalue={endTime}></MyTimePicker></div>
            <Button  className={classes.btn} disabled={false} onClick={addUpdateScheduleHandler}>
            שמור
          </Button>
          <Button  className={classes.btn} disabled={false} onClick={closeScheduleHandler}>
            חזור
          </Button>
          {scheduleID != 0 && <Button  className={classes.btndel} disabled={false} onClick={delScheduleHandler}>
            מחק
          </Button>}
        </Modal>
    )
}

export default AddSchedule;