import { Fragment } from 'react';

import MainNavigation from './MainNavigation';
import SubNavigation from './SubNavigation';
import TenantSubNavigation from './TenantSubNavigation';
import classes from './Layout.module.css';
const Layout = (props) => {
  console.log('subnav: ' + props.NavCode);
  return (
   
    <Fragment>       
        <MainNavigation></MainNavigation>
        {(props.NavCode === 1) && <SubNavigation></SubNavigation>}
        {(props.NavCode === 2) && <TenantSubNavigation></TenantSubNavigation>}
        <div className={classes.main}>{props.children}</div>
    </Fragment>
  );
};

export default Layout;
