import { useEffect, useContext, useState } from "react";
import React from "react";
import NavContext from "../../Store/nav-ctx";
import AuthContext from "../../Store/auth-context";
import useHttp from "../../hooks/use-http";
import { backend } from "../../settings";
import { hours } from "../../settings";
import SchedueRecord from "./ScheduleRecord";
import classes from "./DayTenant.module.css";

/// Get the List of schedules by tenant id

/// print them one after another. if tenant id is 0 print tenant name as well.


const DayTenant = (props) => {
    useEffect(() => {
      console.log('setting sub nav to 2');
      props.setSubNav(2);
    },[]);
    

    const navCTX = useContext(NavContext);
    const authCTX = useContext(AuthContext);
    const roomList = navCTX.RoomList;
    const tenantID = navCTX.CurrentTenant !== null ? navCTX.CurrentTenant : 1;
    const [scheduleList, setScheduleList] = useState(null);
    const [GroupedScheduledList, setGroupScheduleList] = useState(null);

    const HandleSchedules = (schedules) => {
        console.log("schedules data recived: " + schedules ? schedules.length : 0);
       // setDoReload(false);
        // convert schdule object to real dates:
        const objSchedules = schedules.map((x) => {
            const room = roomList.find(room => room.RoomID == x.RoomID);
            const roomName = room ? room.RoomName : "חדר לא נמצא";
          return { ...x, From: new Date(x.From), To: new Date(x.To), RoomName: roomName};
        });
        setScheduleList(objSchedules);
        if(tenantID===0)
        {
            const groupedData = {};
            objSchedules.forEach((item) => {
                if(!groupedData[item.TenantName.trim()]) {
                    groupedData[item.TenantName.trim()] = [item];
                } else {
                    groupedData[item.TenantName.trim()].push(item);
                }
            });
            setGroupScheduleList(groupedData);
        }
        console.log("current schedules after reload: " + objSchedules.length);

        // Generate the schedule items array:

    }


    const httpData = useHttp();
    const { isLoading, httpError: isError, sendRequest: fetchSchedules } = httpData;
    
    const Today = navCTX.CurrentDay;
    console.log(typeof Today);
    console.log(hours.startHour);
    const from = new Date(
      Today.setHours(hours.startHour-2, 0, 0, 0) -
        Today.getTimezoneOffset() * 60 * 1000
    )
      .toISOString()
      .slice(0, 19);
    const to = new Date(
      Today.setHours(hours.endHour, 59, 59, 999) -
        Today.getTimezoneOffset() * 60 * 1000
    )
      .toISOString()
      .slice(0, 19);
  
    useEffect(() => {
      console.log('loading data....');
      const requestConfig = {
        url:
          backend.httpmethod +
          backend.backendURL +
          "/api/schedule/tenant/" +
          tenantID +
          "/" +
          from +
          "/" +
          to,
      };
      console.log("calling fetch schedule: " + requestConfig.url);
      fetchSchedules(requestConfig, HandleSchedules);
    }, [from, to, tenantID]);
    //fetchSchedules(requestConfig,HandleSchedules);
    return (
        <React.Fragment>
            {isLoading && (<p style={{textAlign: "center"}}>Loading....</p>)}     
            {isError && (<p style={{textAlign: "center"}}>Backend server error</p>)}
            {tenantID===0 && <div>
            {GroupedScheduledList && Object.keys(GroupedScheduledList).map((tenantName) => (
                <div key={tenantName}>
                <div className={classes.tenantHeader}>{tenantName}</div>
                <ul className={classes.tenantUL}>
                {GroupedScheduledList[tenantName].map((x) => (
                   <SchedueRecord key={x.ScheduleID} starttime={x.From} endtime = {x.To} scheduleID={x.ScheduleID} tenantID = {x.TenantID} roomName = {x.RoomName} tenantName = {x.TenantName}></SchedueRecord>
                ))}
                </ul>
                </div>
            ))}
            </div>}
            {tenantID !== 0 &&
            <ul className={classes.tenantUL}>
        {scheduleList !== null && scheduleList.map(x => (
            
                <SchedueRecord key={x.ScheduleID} starttime={x.From} endtime = {x.To} scheduleID={x.ScheduleID} tenantID = {x.TenantID} roomName = {x.RoomName} tenantName = {x.TenantName}></SchedueRecord>
            ))}
        </ul>}
        </React.Fragment>
      );

}

export default DayTenant;