import { chainPropTypes } from "@mui/utils";
import { useState, useCallback } from "react";

const useHttp = (requestConfig, applyData) => {
  const [isLoading, setIsLoading] = useState(false);
  const [httpError, setError] = useState(false);

  const sendRequest = useCallback(async (requestConfig, applyData) => {
    setIsLoading(true);
    setError(null);
    console.log('error set to null');
    try {
        console.debug("Calling " + requestConfig.url);
        console.debug("Body: " + requestConfig.body ? JSON.stringify(requestConfig.body): null);
        const response = await fetch(requestConfig.url, {
            method: requestConfig.method ? requestConfig.method : 'GET',
            headers: requestConfig.headers ? requestConfig.headers : {},
            body: requestConfig.body ? JSON.stringify(requestConfig.body): null ,

          });

        if (!response.ok) {
            console.error("Failed" + response.statusText);
            const Errdata = await response.json();

            throw new Error(Errdata);
            }

        const data = await response.json();
        console.log('Invoking applyData Transformation');
        applyData(data);
        } catch (err) {
            //setError((prevstate) => {return {httpError: "שגיאה: " + err.message};});
            setError("שגיאה: " + err.message);
            console.log('error set: ' + err.message);
        }
    setIsLoading(false);
  },[]);

  return {
    isLoading,
    httpError,
    sendRequest,
  };
};

export default useHttp;
