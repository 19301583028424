import React, { useContext } from "react";
import NavContext from "../../Store/nav-ctx";
import DisplayDate from "../Calendar/DisplayDate";
import classes from './SubNavigation.module.css';
import Button from "../UI/Button";
import Select from "react-select";
// eslint-disable-next-line
Date.prototype.addDays = function(days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
}



const SubNavigation = () => {  
  const navCTX = useContext(NavContext);
  

  const thisDay = navCTX.CurrentDay;
  const nextDay = thisDay.addDays(1);
  const prevDay = thisDay.addDays(-1);
  const rooms = navCTX.RoomList;
  console.log("rendering SubNavigation");

  const changeSelectedRoom = (selectedRoom) => 
  {
    console.log('chaning current room to : ' + selectedRoom.value);
    navCTX.onChangeCurrentRoom(selectedRoom.value);
  };
  const roomsOptions = rooms ? rooms.map( (room)=> ({value: room.RoomID, label: room.RoomName})) : console.error('no roomlist');
  //console.log("room options: " + roomsOptions);
  console.log("Navigation: " + thisDay + " to "  +nextDay);
   return (
    <div className={classes.subnav}>
        <Button onClick={() => {navCTX.onChangeDay(prevDay)}} className="buttonRect">Prev</Button>        
         <DisplayDate currentDate={navCTX.CurrentDay}></DisplayDate>
         <div tabIndex={-1}>
         <Select inputProps={{ readOnly: true }} isSearchable={ false } className={classes.selectBox} options={roomsOptions} onChange={changeSelectedRoom} value={roomsOptions ? roomsOptions.filter(function(option) {return option.value === navCTX.CurrentRoom}): null}></Select>
         </div>
        <Button onClick={() => {navCTX.onChangeDay(nextDay)}}>next</Button>
    </div>
  ) 
  };

  // {navCTX.onChangeDay(new Date(navCTX.CurrentDay+1))}
  // value={options.filter(item => item.value === navCTX.CurrentRoom)} onChange={SetSelectedRoom}
export default SubNavigation;